import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';


gsap.registerPlugin(ScrollTrigger);

const scroller = document.querySelector('[data-scroll-container]')

const locoScroll = new LocomotiveScroll({
  el: scroller,
  smooth: true,
  direction: "horizontal",
  smartphone: {
    smooth: true,
    direction: "horizontal",
  },
  tablet: {
    smooth: true,
    direction: "horizontal",
  }
});

locoScroll.on("scroll", ScrollTrigger.update);

//locoScroll.on("scroll", function() {
//  console.log( locoScroll.scroll.instance.scroll.x )
//});

ScrollTrigger.scrollerProxy(scroller, {
//  scrollTop(value) {
//    return arguments.length
//      ? locoScroll.scrollTo(value, 0, 0)
//      : locoScroll.scroll.instance.scroll.y;
//  },
  scrollLeft(value) {
    return arguments.length
      ? locoScroll.scrollTo(value, 0, 0)
      : locoScroll.scroll.instance.scroll.x;
  },
  getBoundingClientRect() {
    return {
      left: 0,
      top: 0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  },
  pinType: scroller.style.transform ? "transform" : "fixed"
});

ScrollTrigger.defaults({
  scroller: scroller
})

gsap.set('section', { backgroundColor: (index, target) => { return target.getAttribute('data-bgcolor') },
                      color: (index, target) => { return target.getAttribute('data-textcolor') }})



gsap.to(".anim-wrap", {
  scrollTrigger: {
    trigger: ".vertical",
    start: () => "left left",
    end: () => "+=" + document.querySelector('.anim-wrap').scrollHeight,
    pin: true,
    pinSpacing: true,
    // anticipatePin: 1,
    scrub: true,
    horizontal: true,
    invalidateOnRefresh: true
    //markers: true
  },
  y: () => { return -(document.querySelector('.anim-wrap').scrollHeight - window.innerHeight) },
  ease: "none"
});



ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
ScrollTrigger.refresh();





// first attempt at GSAP 2012
$(function(){

	var $slider = $('#slider'),
		$slides = $slider.children(),
		slideCount = $slides.length,
		currentSlide = 0,
		duration = 8,
		fadeSpeed = 1,
		$slide1 = $("#slide1"),
		$slide2 = $("#slide2"),
		$slide3 = $("#slide3"),				
		
		$playBtn = $("#playbtn"),
		$pauseBtn = $("#pausebtn"),
		$resumeBtn = $("#resumebtn"),
		$stopBtn = $("#stopbtn"),
		$restartBtn = $("#restartbtn"),
		$reverseBtn = $("#reversebtn"),
		$nextBtn = $("#nextbtn"),
		$prevBtn = $("#prevbtn"),
		
		$slide1Btn = $("#slide1btn"),
		$slide2Btn = $("#slide2btn"),
		$slide3Btn = $("#slide3btn"),
		
		$progressBar = $("#progressBar"), 
		tl = new TimelineMax({ 
						paused:true, 
						repeat:-1//, 
						//onComplete: restartTimeline,
						//onCompleteParams: ["{self}"],
            //onReverseComplete: reverseTimeline,
            //onReverseCompleteParams: ["{self}"]
		});
			
	updateZindex();
  
				
	///////////////////////////////////////////////////////			
	// functions for building nested timelines
  function animateSlide1() {
				
			var slideTL3 = new TimelineMax({ 
					onComplete: prepNext, 
					onCompleteParams: ["{self}", $slide3]
			});
				
			slideTL3
				.add("slide3fade") // adds a label
				//.set($slide3, {'opacity':1})				
				.to($slide3, fadeSpeed, {css:{autoAlpha:0}})
        .add("slide3afterfade") // adds a label
				.to($slide3, duration)
				.from($progressBar, slideTL3.duration(), {scaleX:0, transformOrigin:"0px 0px", ease:Linear.easeNone}, 0);
		
			return slideTL3;
	}
  
	function animateSlide2() {
				
				var slideTL1 = new TimelineMax({ 
						onComplete: prepNext, 
						onCompleteParams: ["{self}", $slide1]
				});
				
				slideTL1
					.add("slide1fade")
					.set($slide1,{'opacity':1})	 // adds a label					
					.to($slide1, fadeSpeed, {css:{autoAlpha:0}})
          .add("slide1afterfade") // adds a label
					.to($slide1, duration) 	
					.from($progressBar, slideTL1.duration(), {scaleX:0, transformOrigin:"0px 0px", ease:Linear.easeNone}, 0);
					
				return slideTL1;		
	}
			
	function animateSlide3() {
						
			var slideTL2 = new TimelineMax({ 
					onComplete: prepNext, 
					onCompleteParams: ["{self}", $slide2]
			});
				
			slideTL2
				.add("slide2fade")  // adds a label
				//.set($slide2,{'opacity':1})						
				.to($slide2, fadeSpeed, {css:{autoAlpha:0}})
				.add("slide2afterfade") // adds a label
        .to($slide2, duration)		
				.from($progressBar, slideTL2.duration(), {scaleX:0, transformOrigin:"0px 0px", ease:Linear.easeNone}, 0);
					
			return slideTL2;
	}
	

	// update zindex on all slides
	function updateZindex(){
			
			$slider.children().css('zIndex',function(i){
					return slideCount-i;
			});				
	}			
		
	// prep next slide
	function prepNext(timeline, slide){
			slide.appendTo(slide.parent());
			updateZindex();
			timeline.set(slide,{autoAlpha:1});
			currentSlide = ++currentSlide % slideCount;
			//$('#msg').html(currentSlide);
	}
		
	function showProgress(timeline) {		
			timeline.set($progressBar, {scaleX:timeline.progress(), transformOrigin: "0px 0px"});
	
	}
  
  
  $(window).load(function() {
			
		//////////////////////////////////
		// build timeline	
		tl		
    .add(animateSlide1(), "slide1")
		.add(animateSlide2(), "slide2")
		.add(animateSlide3(), "slide3")
    .progress(1).progress(0)
		.play();
	  
	});
  	
	//controls
	$slide1Btn.click(function() {
			tl.play("slide1afterfade");
	});
						
	$slide2Btn.click(function() {
			tl.play("slide2afterfade");
	});
					
	$slide3Btn.click(function() {
			tl.play("slide3afterfade");
	});
	
	$playBtn.click(function() {
			tl.play("slide1afterfade");
	});
	
	$pauseBtn.click(function() {
			tl.pause();
	});
	
	$resumeBtn.click(function() {
			tl.resume();
	});
	
	$stopBtn.click(function() {
			tl.stop();
	});
	
	$restartBtn.click(function() {
			tl.restart();
	});
	
	$reverseBtn.click(function() {
			tl.reverse();
	});
		
	$slider.on("mouseenter",function(){					
			tl.pause();
	}).on("mouseleave",function(){
			tl.resume();
	});	
		
});